@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.min.css);
/* html {
  height: 100%;
}
body {
  background: #DCDCDC;
  height: 100%;
} */

/* #main {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: space-around;
} */

#menu-responsive {
  flex-basis: 90%;
  display: flex;
  flex-direction: column;
  background: #191939;
  border-top: 2px solid #463e7a;
  min-height: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: fixed;
}

#headline {
  align-self: center;
}

#content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}
.menu-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.nav-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  stroke: #d9cbd5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  text-decoration: none;
  padding: 0px 12px;
  gap: 5px;
}
.nav-item__txt {
  /* color: #d9cbd5;
  text-transform: uppercase;
  font-size: 12px; */
  opacity: 1;
  font-size: 10px;
  line-height: 1rem;
  color: rgb(123, 108, 185);
  font-weight: 700;
  text-transform: uppercase;
  transition: none 0s ease 0s;
  text-align: center;
}

@media screen and (max-width: 480px) {
  #menu-responsive {
    display: block;
  }
}
@media screen and (min-width: 481px) and (max-width: 600px) {
  #menu-responsive {
    display: block;
  }
}
@media screen and (min-width: 601px) and (max-width: 1024px) {
  #menu-responsive {
    display: none;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1366px) {
  #menu-responsive {
    display: none;
  }
}
@media screen and (min-width: 1367px) {
  #menu-responsive {
    display: none;
  }
}
