:root {
  --primary-color: #191939;
  --white: #FFFFFF;
  --sidebar-primary: #191939;
  --sidebar-primary-hover: #463E7A;
  --sidebar-background: #191939;
  --background: #F1F3FF;
  --text-link: #d9cbd5;
  --headline: #CBD1D8;
  --expand-button: #605499;
  --logout: #FA7575;
}

.indexHeader {
    width: 100%;
    background-color: #191939;
    height: 80px;
    position: fixed;
    z-index: 999999;
}
.indexHeaderCtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.indexNav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #191939;
    /* min-width: 120vh; */
    padding-top: 5px;
}
.navList {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}
.navListItem {
    padding: 0 10px;
    margin: 0 10px;
}
.nav-list__txt {
    font-size: 18px;
    color: #9CA3AF;
    font-weight: 700;
    transition-duration: .5s;
}

.nav-list__txt:hover {
    color: #eeaf0e;
}
.logoCtnImg {
    width: 60px;
    position: relative;
    top: 5px;
}
.logoCtnImg:hover {
    transform: scale(1.08);
    transition-duration: .5s;
    /* cursor: url('../../assets/cursor/pepe-cursor-v2.png'), auto; */
}
.banner-ctn__main {
    width: 100%;
}

.loginWdiscord {

}

.loginWdiscordBtn {
    color: #d9c4ca;
    background-color: #463e7a;
    padding: 13px 15px 13px 40px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
}
.loginWdiscordBtn:hover {
    color: #d9c4ca;
    background-color: #2c2852;
    border-radius: 6px;
}
.loginWdiscordLogo {
    position: relative;
    width: 20px;
    left: 33px;
    top: 3px;
}

.sidebar {
    z-index: 10000;
    left: 0;
    min-height: 100%;
    padding: 1rem 0px;
    width: 19rem;
    display: flex;
    color: var(--white);
    flex-direction: column;
    /* background-color: var(--sidebar-background); */
    /* background-image: linear-gradient(216deg, rgba(77, 77, 77,0.05) 0%, rgba(77, 77, 77,0.05) 25%,rgba(42, 42, 42,0.05) 25%, rgba(42, 42, 42,0.05) 38%,rgba(223, 223, 223,0.05) 38%, rgba(223, 223, 223,0.05) 75%,rgba(36, 36, 36,0.05) 75%, rgba(36, 36, 36,0.05) 100%),linear-gradient(44deg, rgba(128, 128, 128,0.05) 0%, rgba(128, 128, 128,0.05) 34%,rgba(212, 212, 212,0.05) 34%, rgba(212, 212, 212,0.05) 57%,rgba(25, 25, 25,0.05) 57%, rgba(25, 25, 25,0.05) 89%,rgba(135, 135, 135,0.05) 89%, rgba(135, 135, 135,0.05) 100%),linear-gradient(241deg, rgba(55, 55, 55,0.05) 0%, rgba(55, 55, 55,0.05) 14%,rgba(209, 209, 209,0.05) 14%, rgba(209, 209, 209,0.05) 60%,rgba(245, 245, 245,0.05) 60%, rgba(245, 245, 245,0.05) 69%,rgba(164, 164, 164,0.05) 69%, rgba(164, 164, 164,0.05) 100%),linear-gradient(249deg, rgba(248, 248, 248,0.05) 0%, rgba(248, 248, 248,0.05) 32%,rgba(148, 148, 148,0.05) 32%, rgba(148, 148, 148,0.05) 35%,rgba(202, 202, 202,0.05) 35%, rgba(202, 202, 202,0.05) 51%,rgba(181, 181, 181,0.05) 51%, rgba(181, 181, 181,0.05) 100%),linear-gradient(92deg, rgb(25,25,57),rgb(25,25,57)); */
    background-image: url('../../assets/img/back-header.png');
    background-attachment: fixed;
    transition: width 0.2s ease-in-out;
    /* margin-top: 5px; */
    z-index: 9999;
  }
  
  

.collapsed {
  width: 5rem;
  display: flex;
  align-items: center;
}
  
.collapsed .hide {
    position: absolute;
    display: none;
  }
  
  /*? search wrapper */
  .searchWrapper {
    padding: 0 1rem;
    position: relative;
  }
  
  .searchWrapper input {
    background-color: var(--background);
    height: 2.5rem;
    width: 100%;
    border-radius: 0.5rem;
    padding: 0 0.5rem;
    padding-left: 2rem;
    flex-grow: 1;
    outline: none;
    border: none;
  }
  
  .searchWrapper svg {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 1.625rem;
    transform: translateY(-50%);
    pointer-events: none;
    right: 1.5rem;
  }
  
  .collapsed .searchWrapper svg {
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    stroke: var(--sidebar-primary-hover);
  }
  
  .searchWrapper input::-webkit-input-placeholder {
    color: var(--sidebar-primary-hover);
    white-space: nowrap;
  }
  
  .collapsed .searchWrapper input {
    max-width: 2.5rem;
  }
  
  .collapsed .searchWrapper input::-webkit-input-placeholder {
    color: transparent;
  }
  
  /*? sidebar top */
  
  .sidebarTopWrapper {
    display: flex;
  }
  
  .sidebarTop {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    height: 4rem;
    padding: 1rem;
  }
  
  .collapsed .sidebarTop {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .logoWrapper {
    margin-top: -0.25rem;
    display: flex;
    align-items: center;
    color: var(--text-link);
    font-weight: 700;
    text-decoration: none;
    font-size: 1.35rem;
    gap: 0.5rem;
    padding: 0 1rem;
  }
  
  .logoSmall {
    height: 45px;
    min-width: 45px;
    overflow: hidden;
  }
  
  /*? menu links */
  
  .sidebarLinks {
    margin-top: 1rem;
    width: 100%;
  }
  
  .sidebarLinks h2 {
    margin-left: 1rem;
    color: var(--headline);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.125rem;
    margin-bottom: 0.5rem;
    animation: fadeIn 0.2s ease-in-out;
  }
  
  .collapsed h2 {
    display: none;
  }
  
  .sidebarLinks ul {
    list-style-type: none;
    position: relative;
    display: flex;
    column-gap: 0.5rem;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  
  .sidebarLinks li {
    color: var(--text-link);
  }
  
  .collapsed .sidebarLinks li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sidebarLinks li svg {
    stroke: var(--text-link);
    width: 1.;
    height: 1.75rem;
    min-width: 1.75rem;
    background-color: #2c2852;
    border-radius: 9px;
    padding: 9px;
    width: 40px;
    height: 40px;
  }
  
  .sidebarLinks li a:hover {
    background-color: var(--sidebar-primary-hover);
  }
  
  .sidebarLinks li a {
    color: var(--text-link);
    flex-grow: 1;
    padding: 0 1rem;
    font-size: 1.25rem;
    display: flex;
    gap: 1.75rem;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
  }
  
  .sidebarLinks li a .link {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    animation: fadeIn 0.2s ease-in-out;
  }
  
  .sidebarLinks li a img {
    height: 2.125rem;
    width: 2.125rem;
  }
  
  .sidebarLinks .active:hover {
    background-color: var(--sidebar-primary-hover);
  }
  
  .sidebarLinks .active {
    text-decoration: none;
    background-color: var(--sidebar-primary-hover);
    color: var(--text-link);
  }
  
  .sidebarLinks .active svg {
    stroke: var(--text-link);
  }
  
  /* ?tooltip */
  .tooltip {
    position: relative;
  }
  
  .tooltip .tooltipContent::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0%;
    margin-left: -0.6rem;
    margin-top: -0.3125rem;
    border-width: 5px;
    border-style: solid;
    border-color: transparent var(--primary-color) transparent transparent;
  }
  
  .tooltip .tooltipContent {
    visibility: hidden;
    background-color: var(--primary-color);
    color: var(--white);
    text-align: center;
    border-radius: 0.375rem;
    padding: 0.375rem 0.75rem;
    position: absolute;
    z-index: 1;
    left: 5.625rem;
  }
  
  .collapsed .tooltip:hover .tooltipContent {
    visibility: visible;
  }
  
  /*? profile part */
  .sidebarProfile {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex-direction: row;
    padding: 0 1rem;
    color: var(--text-link);
    overflow-x: hidden;
    min-height: 2.6125rem;
  }
  
  .avatarWrapper {
    position: relative;
    display: flex;
  }
  .avatarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 15px;
    box-shadow: rgb(197, 106, 24) 0px -3px 0px 0px inset, rgb(255, 234, 47) 0px 2px 0px 0px inset;
    transition: background-size 0.3s ease-in-out 0s;
    background: linear-gradient(420deg, rgb(255, 206, 0) 0%, rgb(255, 206, 0) 49%, rgb(238, 175, 14) 49%, rgb(238, 175, 14) 100%) left center / 100% 100% no-repeat;
    padding: 4px 7px;
    cursor: pointer;
  }
  .avatarContainer:hover {
    background-size: 300% 100%;
    box-shadow: rgb(197, 106, 24) 0px -3px 0px 0px inset, rgb(255, 234, 47) 0px 2px 0px 0px inset;
    
  }
  .avatarUser {
    font-size: 15px;
    color: #ffffff;
    font-weight: 600;
    padding-right: 15px;
    text-shadow: 2px 0 #c56a18, -2px 0 #c56a18, 0 2px #c56a18, 0 -2px #c56a18, 1px 1px #c56a18, -1px -1px #c56a18, 1px -1px #c56a18, -1px 1px #c56a18; 
  }
  .avatar {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .avatarName {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    white-space: nowrap;
    animation: fadeIn 0.2s ease-in-out;
  }
  
  .userName {
    font-weight: 600;
    text-align: left;
    color: var(--text-link);
    animation: fadeIn 0.2s ease-in-out;
  }
  
  .email {
    color: var(--text-link);
    font-size: 0.8125rem;
    animation: fadeIn 0.2s ease-in-out;
  }
  
  .logout {
    animation: fadeIn 0.2s ease-in-out;
    margin-left: auto;
  }
  
  .logout svg {
    color: var(--logout);
  }

  .posPubli {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .videoMain {
    margin-top: 2rem;
    border-radius: 25px;
    width: 245px;
    position: relative;
    /* left: 7px; */

  }

  .collapsed .logout  {
    display: none;
  }
  .collapsed .videoMain {
    display: none;
    
  }
  
  .collapsed .videoMain:hover {
    transform: scale(3);
  }

  /*? Expand button */
  
  .expandBtn {
    z-index: 200;
    position: absolute;
    top: 25px;
    left: 20px;
    display: grid;
    place-items: center;
    cursor: pointer;
    background-color: #2c2852;
    z-index: 2;
    /* right: -1.125rem; */
    width: 40px;
    height: 40px;
    border-radius: 9px;
    border: 4px solid #090c1d;
    transition: all .5s;
  }
  .expandBtn:hover { 
    background-color: #463e7a;
  }
  
  .expandBtn svg {
    transform: rotate(-180deg);
    stroke: var(--white);
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .collapsed .expandBtn svg {
    transform: rotate(-360deg);
  }
  
  .bottomLinks {
    margin-top: auto;
  }
 
  
  @keyframes fadeIn {
    from {
      width: 0;
      opacity: 0;
    }
  
    to {
      opacity: 1;
      width: 100%;
    }
  }

.suspenseLoading {
  width: 11rem;
  background: #2c2852;
  height: 32px;
  border-radius: 9px;
}

@media screen and (max-width: 480px) {
  .sidebar {
    display: none;
  }
  .index-nav {
      min-width: 44vh;
  }
  .nav-list__item {
      padding: 0 5px;
      margin: 0 5px;
  }
  .nav-list__txt {
      font-size: 14px;
  }
  .logo-ctn__img {
      width: 65px;
      margin-top: 2rem;
  }
  
}

@media screen and (min-width: 481px) and (max-width: 600px) {
  .sidebar {
    display: none;
  }
  .index-nav {
      min-width: 44vh;
  }
}
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .index-nav {
      min-width: 58vh;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .index-nav {
      min-width: 86vh;
  }
}

@media screen and (min-width: 1367px) {
  .index-nav {
      min-width: 120vh;
  }
}

